import imageSize from 'base64image-dimensions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { NoScriptPolyfill } from '@/utils/noScriptPolyfill';

import './index.scss';

export const LazyImageWithPlaceholder = ({ placeholder, src, srcSet, className, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const ref = useRef();

  const onLoad = () => {
    setIsLoaded(true);
  };

  const aspectRatio = useMemo(() => {
    if (placeholder) {
      const dimensions = imageSize(placeholder);
      if (dimensions.width && dimensions.height) {
        return dimensions.width / dimensions.height;
      }
    }
    return undefined;
  }, [placeholder]);

  useEffect(() => {
    if (ref.current && ref.current.complete) onLoad();
  });

  return (
    <div
      className={['lazyImageWithPlaceholder', className].join(' ')}
      data-loaded={isLoaded ? '1' : undefined}
    >
      <NoScriptPolyfill>
        <img
          className="lazyImageWithPlaceholder__noScriptImage"
          src={src}
          srcSet={srcSet}
          alt={alt}
          style={{ aspectRatio }}
        />
      </NoScriptPolyfill>
      {placeholder && (
        <img
          src={placeholder}
          className="lazyImageWithPlaceholder__placeholder noScriptDisplayNone"
          alt={alt}
          style={{ aspectRatio }}
        />
      )}
      <LazyLoadComponent visibleByDefault={isLoaded} delayTime={0}>
        <img
          ref={ref}
          src={src}
          srcSet={srcSet}
          className="lazyImageWithPlaceholder__img noScriptDisplayNone"
          alt={alt}
          onLoad={onLoad}
          style={{ aspectRatio }}
        />
      </LazyLoadComponent>
    </div>
  );
};
