import Link from 'next/link';

import { ReactComponent as SVGArrow } from '@/assets/svg/arrow.svg';
import { Animation } from '@/components/ui/animation';
import { LazyImageWithPlaceholder } from '@/components/ui/lazy-image-with-placeholder';

import './index.scss';

export const IndustryItem = ({
  title,
  pictureSrcset,
  placeholder,
  content,
  link,
  counter,
  backgroundColor
}) => (
  <Animation animationType="cardTop">
    <Link className="industry-card" href={link} style={{ '--background-color': backgroundColor }}>
      <LazyImageWithPlaceholder
        className="industry-card__img"
        srcSet={pictureSrcset}
        alt={title}
        placeholderSrc={placeholder}
      />
      <div className="industry-card__content">
        <span className="industry-card__index">{counter}</span>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
      <button>
        Learn more
        <SVGArrow />
      </button>
    </Link>
  </Animation>
);
