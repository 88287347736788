/* eslint-disable import/no-unresolved */
import { IndustryItem } from './industryItem';

import './index.scss';

const industriesListContent = [
  {
    title: 'Automotive',
    content:
      'The automotive industry should always keep up with the technologies. Let the customers experience your full potential by implementing unconventional solutions.',
    link: '/industries/automotive',
    pictureSrcsetName: 'automotive',
    pictureSrcset: require('@/assets/images/industry-automotive.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-automotive.webp?placeholder'),
    backgroundColor: '#b81c4a'
  },
  {
    title: 'Finance',
    content:
      'Transform your vision of finance tech solutions. We provide both deep technology and domain knowledge, which allows banks, market utilities, and fintech firms to rely on us.',
    link: '/industries/finance',
    pictureSrcsetName: 'finance',
    pictureSrcset: require('@/assets/images/industry-finance.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-finance.webp?placeholder'),
    backgroundColor: '#1156a6'
  },
  {
    title: 'Health',
    content:
      'We partner with companies in the healthcare sector, providing tech solutions that improve their efficiency. The software we develop complies with all HIPAA/HITECH standards.',
    link: '/industries/health',
    pictureSrcsetName: 'health',
    pictureSrcset: require('@/assets/images/industry-health.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-health.webp?placeholder'),
    backgroundColor: '#38952d'
  },
  {
    title: 'Government',
    content:
      'Taking into account the sector’s requirements on transparency and increased compliance, we deliver efficient and innovative solutions for governmental organizations.',
    link: '/industries/government',
    pictureSrcsetName: 'government',
    pictureSrcset: require('@/assets/images/industry-government.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-government.webp?placeholder'),
    backgroundColor: '#1e9cb2'
  },
  {
    title: 'Media & Entertainment',
    content:
      "Fulfill your ambition for a multichannel and content-rich software solution. We provide custom implementations for digital services that will meet your customers' requirements.",
    link: '/industries/media',
    pictureSrcsetName: 'media',
    pictureSrcset: require('@/assets/images/industry-media.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-media.webp?placeholder'),
    backgroundColor: '#e74735'
  },
  {
    title: 'Logistics & Transport',
    content:
      'Efficient logistics software plays a crucial role in your supply chain’s performance. Our full-circle development will transform your software into a fully scalable product.',
    link: '/industries/logistics',
    pictureSrcset: require('@/assets/images/industry-logistics.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-logistics.webp?placeholder'),
    backgroundColor: '#ff8036'
  },
  {
    title: 'Retail & Ecommerce',
    content:
      'Client-oriented businesses can rely on our expertise in developing solutions that require automation, tracking and payment systems integration.',
    link: '/industries/ecommerce',
    pictureSrcset: require('@/assets/images/industry-retail.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-retail.webp?placeholder'),
    backgroundColor: '#7D1941'
  },
  {
    title: 'Telecom',
    content:
      'We let our clients innovate in the telecommunication sector by using advanced tools and technologies. We offer user-friendly solutions of any complexity.',
    link: '/industries/telecom',
    pictureSrcset: require('@/assets/images/industry-telecom.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-telecom.webp?placeholder'),
    backgroundColor: '#232156'
  },
  {
    title: 'Travel',
    content:
      'Build a flexible, secure, and scalable travel software with a team of experts in the field. We can develop sophisticated solutions of any complexity.',
    link: '/industries/travel',
    pictureSrcset: require('@/assets/images/industry-travel.webp?2x-srcset'),
    placeholder: require('@/assets/images/industry-travel.webp?placeholder'),
    backgroundColor: '#3F1596'
  }
];

export const IndustriesList = ({ listLength = 9 }) => (
  <section className="container section-padding">
    <h2 className="section-title">Industries</h2>
    <div className="industries-list__container three-column-grid">
      {industriesListContent.slice(0, listLength).map((el, index) => (
        <IndustryItem {...el} key={index} counter={`0${index + 1}`} />
      ))}
    </div>
  </section>
);
